<template>
    <v-flex xs6>
        <v-text-field v-model="filter" append-icon="search" label="Localizar no Banco de Dados" outlined @keyup.13.prevent="pesquisar"></v-text-field>
    </v-flex>
</template>

<script>
    export default {
        data () {
            return {
                filter: ''
            }
        },
        methods: {
            pesquisar () {
                this.$emit('pesquisarDados', this.filter)
            }
        }
    }
</script>

<style scoped>

</style>