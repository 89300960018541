<template>
<span>
    <v-toolbar flat color="white">
        <v-toolbar-title class="lime--text lighten-1">Gerenciar Cupons</v-toolbar-title>
        <v-divider class="mx-2" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <template>
            <v-btn color="blue-grey darken-2" dark class="mb-1" @click.prevent="loadStoreCoupons">
                <v-icon dark>sync</v-icon>Atualizar
            </v-btn>
            <v-btn color="success" dark class="mb-1 ml-2" :to="{name: 'store-coupons.add'}">
                <v-icon class="mdi mdi-plus"></v-icon>Adicionar Novo Cupom
            </v-btn>
        </template>
    </v-toolbar>

    <v-card>
        <v-card-title>
            <v-text-field xs6 v-model="search" append-icon="search" placeholder="Localizar na Grid" single-line hide-details outlined></v-text-field>
        </v-card-title>
        <v-data-table :headers="headers" :items="storecoupons" :search="search" :rowsPerPageItems="rowsPerPageItems">

            <template v-slot:[`item.stor_co_tipo_desconto`]="{ item }">
                <td>{{ (item.stor_co_tipo_desconto == 'P' ? 'Porcentagem' : 'Real') }}</td>
            </template>

            <template v-slot:[`item.stor_co_valor_desconto`]="{ item }">
                <p class="text-right pt-4">{{ item.stor_co_valor_desconto }}</p>
            </template>

            <template v-slot:[`item.stor_co_valor_acima_de`]="{ item }">
                <p class="text-right pt-4">{{ item.stor_co_valor_acima_de }}</p>
            </template>

            <template v-slot:[`item.stor_co_quantidade`]="{ item }">
                <p class="text-center pt-4">{{ item.stor_co_quantidade }}</p>
            </template>

            <template v-slot:[`item.stor_co_usados`]="{ item }">
                <p class="text-center pt-4">{{ item.stor_co_usados }}</p>
            </template>

            <template v-slot:[`item.stor_co_status`]="{ item }">
                <td>
                    <v-switch color="lime" v-model="item.stor_co_status" @change="onChangeEventHandler(item.stor_co_id, $event)" class="my-switch"></v-switch>
                </td>
            </template>

            <template v-slot:[`item.acoes`]="{ item }">
                <v-tooltip top color="blue white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="blue lighten-1" @click.prevent="editStoreCoupon(item)" v-on="on">
                            <v-icon dark>mdi-pencil</v-icon>
                        </v-btn>
                    </template>
                    <span>Editar</span>
                </v-tooltip>

                <v-tooltip top color="red white--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="red lighten-1" @click.prevent="deleteStoreCoupon(item)" v-on="on">
                            <v-icon dark class="mdi mdi-delete"></v-icon>
                        </v-btn>
                    </template>
                    <span>Excluir</span>
                </v-tooltip>

                <v-tooltip top color="lime black--text">
                    <template v-slot:activator="{ on }">
                        <v-btn icon color="lime lighten-1" @click.prevent="detailStoreCoupon(item)" v-on="on">
                            <v-icon dark class="mdi mdi-reorder-horizontal"></v-icon>
                        </v-btn>
                    </template>
                    <span>Detalhes</span>
                </v-tooltip>

            </template>


            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning">Voce procurou por "{{ search }}" nenhum item localizado.</v-alert>
            </template>
        </v-data-table>
    </v-card>
</span>
</template>

<script>
import {
    URL_BASE
} from "../../../config/configs";
import SearchForm from "../partials/SearchComponent";

export default {
    name: "StoreCouponComponent",
    created() {
        this.loadStoreCoupons(1);
    },
    computed: {
        params() {
            return {
                search: "",
                page: this.storecoupons.current_page
            };
        }
    },
    data() {
        return {
            search: "",
            rowsPerPageItems: [
                10,
                20,
                30,
                {
                    text: "$vuetify.dataIterator.rowsPerPageAll",
                    value: -1
                }
            ],
            headers: [{
                    text: "Tipo",
                    align: "left",
                    sortable: false,
                    value: "stor_co_tipo_de_cupom"
                },
                {
                    sortable: false,
                    text: "Nome",
                    value: "stor_co_nome"
                },
                {
                    sortable: false,
                    text: "Código",
                    value: "stor_co_codigo"
                },
                {
                    sortable: false,
                    align: "left",
                    text: "Tipo de desconto",
                    value: "stor_co_tipo_desconto"
                },
                {
                    sortable: false,
                    align: "right",
                    text: "Valor",
                    value: "stor_co_valor_desconto"
                },
                {
                    sortable: false,
                    align: "right",
                    text: "Valor acima de",
                    value: "stor_co_valor_acima_de"
                },
                {
                    sortable: false,
                    text: "Data Inicial",
                    value: "stor_co_data_inicio"
                },
                {
                    sortable: false,
                    text: "Data Final",
                    value: "stor_co_data_fim"
                },
                {
                    sortable: false,
                    align: "center",
                    text: "Quantidade",
                    value: "stor_co_quantidade"
                },
                {
                    sortable: false,
                    align: "center",
                    text: "Utilizado",
                    value: "stor_co_usados"
                },
                {
                    sortable: false,
                    text: "Status",
                    value: "stor_co_status"
                },
                {
                    text: "Ações",
                    align: "center",
                    sortable: false,
                    value: "acoes"
                }
            ],
            storecoupons: [],
            base_url: URL_BASE
        };
    },
    methods: {
        loadStoreCoupons(page) {
            this.$store
                .dispatch("loadStoreCoupons", {
                    ...this.params,
                    page
                })
                .then(response => {
                    console.log(response);
                    this.storecoupons = response;
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "info",
                        title: "Informação",
                        text: "Dados não localizado",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        editStoreCoupon(item) {
            this.$router.push({
                name: "store-coupons.edit",
                params: {
                    stor_co_id: item.stor_co_id
                }
            });
        },

        detailStoreCoupon(item) {
            this.$router.push({
                name: "store-coupons.detail",
                params: {
                    stor_co_id: item.stor_co_id
                }
            });
        },
        deleteStoreCoupon(item) {
            this.$swal({
                title: "Você tem certeza?",
                text: "Você não poderá reverter isso",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#D32F2F",
                cancelButtonColor: "#1976D2",
                confirmButtonText: "Sim, Exclua!"
            }).then(result => {
                if (result.value) {
                    this.$store
                        .dispatch("destroyStoreCoupon", item.stor_co_id)
                        .then(response => {
                            if (response.data.success) {
                                this.loadStoreCoupons(1);
                            } else {
                                this.$swal({
                                    position: "center",
                                    icon: "warning",
                                    title: "Atenção!",
                                    text: response.data.message,
                                    showConfirmButton: true,
                                    timer: 6000
                                });
                            }
                        })
                        .catch(error => {
                            this.$swal({
                                position: "center",
                                icon: "error",
                                title: "Opssss!",
                                text: error.data.message,
                                showConfirmButton: true,
                                timer: 6000
                            });
                        });
                }
            });
        },
        onChangeEventHandlerStatus(stor_co_id, value) {
            this.$store
                .dispatch("updateStoreCouponStatus", {
                    stor_co_id: stor_co_id,
                    stor_co_status: value
                })
                .then(() => {
                    this.$swal({
                        position: "center",
                        icon: "success",
                        title: "Sucesso",
                        text: "Status do Produto atualizado com sucesso",
                        showConfirmButton: true,
                        timer: 6000
                    });
                })
                .catch(error => {
                    this.$swal({
                        position: "center",
                        icon: "error",
                        title: "Opssss!",
                        text: "Erro ao atualizar status do Produto",
                        showConfirmButton: true,
                        timer: 6000
                    });
                });
        },
        filter(filter) {
            this.params.search = filter;
            this.loadStoreCoupons(1);
        }
    },
    components: {
        SearchForm: SearchForm
    }
};
</script>

<style scoped>
.avatar {
    height: 40px;
    margin-top: 0.4rem;
}

.foto-ampliada {
    max-width: 100%;
}
</style>
